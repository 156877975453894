<template>
  <section id="master-template-rekomendasi-layanan">
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <div class="d-flex justify-content-end mb-2">
            <b-button
              class="btn btn-success"
              @click="addTemplate()"
            >
              Tambah Data Master
            </b-button>
          </div>

          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div class="alert-body">
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span v-if="props.column.field == 'action'">
                <b-button
                  variant="info"
                  size="sm"
                  class="m-25"
                  @click="editTemplate(props.row)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  /></b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      ok-variant="success"
      cancel-title="Batal"
      ok-title="Simpan"
      centered
      title="Tambah Template Rekomendasi Layanan"
      @ok="simpanTemplate()"
    >
      <b-card-text>
        <b-form-group
          label="Teks Rekomendasi"
          label-for="vi-rekomendasi"
        >
          <validation-provider
            #default="{ errors }"
            name="Teks Rekomendasi"
            rules="required"
          >
            <b-form-textarea
              id="vi-rekomendasi"
              v-model="rekomendasi"
              rows="4"
              required
              name="rekomendasi"
              placeholder="Teks Rekomendasi"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-card-text>
    </b-modal>
  </section>

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormTextarea, BRow, BButton, BCol, BCard, BModal, BPagination, BFormGroup, BFormSelect, BCardText, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'

export default {
  components: {
    BAlert,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BButton,
    BCard,
    BModal,
    BRow,
    BCol,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ValidationProvider,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === '10') {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      required,
      userData: getUserData(),
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      rekomendasi: '',
      rekomendasi_id: '',
      pageLength: 10,
      columns: [
        {
          label: 'Teks',
          field: 'rekomendasi',
          filterOptions: {
            enabled: true,
            placeholder: 'Teks',
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const formData = {
        domain: 'LAYANAN',
        length: 200,
      }
      this.$http.post('/system/references-recommendation/list', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
          }
        })
    },
    clearTemplate() {
      this.rekomendasi_id = ''
      this.rekomendasi = ''
      this.alert = false
    },
    addTemplate() {
      this.rekomendasi_id = ''
      this.rekomendasi = ''

      this.$refs['modal-komponen'].show()
    },
    editTemplate(data) {
      this.rekomendasi_id = data.rekomendasi_id
      this.rekomendasi = data.rekomendasi

      this.$refs['modal-komponen'].show()
    },
    simpanTemplate() {
      const formData = {
        domain: 'LAYANAN',
        tipe_rekomendasi: 0,
        rekomendasi_text: this.rekomendasi,
      }
      if (this.rekomendasi_id !== '') {
        formData.rekomendasi_id = this.rekomendasi_id
      }

      this.$http.post('/system/references-recommendation/add', formData, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = true
            this.alertDetail = {
              variant: 'success',
              title: 'Data Saved',
              text: res.data.message,
            }
            this.getData()

            setTimeout(() => {
              this.clearTemplate()
            }, 2000)
          } else {
            this.alert = true
            this.alertDetail = {
              variant: 'danger',
              title: 'Error Found',
              text: res.data.message,
            }
          }
        })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
